import React from "react";
import img from "../image/Group 2201978.png";

function DirectorMessage() {
  return (
    <section className="w-full px-8 py-8 sm:py-6 lg:py-16 flex flex-col lg:flex-row items-center justify-center p-6 lg:p-0 responsive-mini">
      <div className="w-full lg:w-1/2 flex items-center justify-center lg:justify-end responsive-directorMessage">
        <div className="w-full lg:w-full xl:w-full bg-[#34438C] md:p-12 lg:p-20 xl:p-16 text-justify flex flex-col justify-center gap-6 text-white directorMessage">
          <h4 className="text-2xl md:text-3xl text-white lg:text-4xl text-center lg:text-left">
            Director Message
          </h4>
          <h5 className="sm:text-xs lg:text-xs text-sm md:text-base text-center lg:text-left">
            Dear customers, As the director of our group of seven companies, I'm
            thrilled to share our enthusiasm and ambition for customer
            satisfaction and national development. We've been leading in
            construction, real estate, IT, and education for 8 years. We're
            committed to providing exceptional products and services while
            contributing to India's growth. Thank you for choosing us.
          </h5>
        </div>
      </div>
      <div className="w-full lg:w-1/2 flex items-center justify-center mt-6 lg:mt-0 responsive-directorMessage">
        <img
          className="w-6/12 md:w-6/12 lg:w-6/12"
          src={img}
          alt="KTRC Group"
        ></img>
      </div>
    </section>
  );
}

export default DirectorMessage;
