import React from 'react'
import SupportUs from './SupportUs'
import ContactCards from './ContactCards'
import ContactDetails from './ContactDetails'

function ContactUs() {
  return (
    <>
   <SupportUs/>
   <ContactCards/>
   <ContactDetails/>
   </>
  )
}

export default ContactUs