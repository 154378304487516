import BuildDream from "./BuildDream";
import AboutUs from "./AboutUs";
import OurWorkPortfolio from "./OurWorkPortfolio";
import KtrcGroup from "./KtrcGroup";
import OurServices from "./OurServices";
import Testimonial from "./Testimonial";
import OurMissionAndVision from "./OurMissionAndVision";
function Home() {
  return (
    <>
      <section id="buildDream" className="px-8"><BuildDream /></section>
      <section id="aboutUs"><AboutUs /></section>
      <section id="ourWorkPortfolio"><OurWorkPortfolio /></section>
      <section id="ktrcGroup"><KtrcGroup /></section>
      <section id="testimonial"><Testimonial /></section>
      <section id="ourServices"><OurServices /></section>
      <section id="ourMissionAndVision"><OurMissionAndVision /></section>
    </>
  );
}

export default Home;
