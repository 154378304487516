import React from "react";
import "./AboutUs.css"; // CSS styles for this component
import img from "../image/Group 2201965.png";
import img1 from "../image/1942.png";
import img2 from "../image/Group 2201972.png";
import img3 from "../image/Group 2201969.png";
import img4 from "../image/Group 2201970.png";
import img5 from "../image/Group 2201971.png";
const AboutUsWithStats = () => {
  return (
    <div className="about-us-with-stats">
      {/* About Us Section */}
      <section className="about-us-section">
        <div className="about-us-content">
          {/* Text Description on the Left */}
          <div className="text-container">
            <h3 className="text-lg">About Us</h3>
            <h4 className="text-4xl font-bold mb-4 text-black">WELCOME TO OUR COMPANY</h4>
            <div className="flex flex-wrap gap-4 items-center">
              <img src={img1} alt="" className="w-36"></img>
              <p className="about-us-text text-justify max-w-lg">
                KTRC GROUP WAS FOUNDED BY ER. CHITRANSHU KOLHEKAR IN 2014. KTRC
                GROUP is a group of multiple companies that is delivering
                quality business in the field of Construction & Consultancy,
                Real Estate, Land Survey, Information Technology, Architects &
                Building Designing, Skill Development. We undertake a variety of
                projects for a wide range of clientele, from small private
                developments to large government projects.
              </p>
            </div>
          </div>

          {/* Right Image */}
          <div className="image-container">
            <img
              src={img} // Replace with your engineer image path
              alt="Engineer"
              className="since-image"
            />
          </div>
        </div>
      </section>

      {/* Stats Section */}
      <section className="stats-section">
        <div className="stat-item">
          <img src={img2} alt=""></img>
        </div>
        <div className="stat-item">
          <img src={img3} alt=""></img>
        </div>
        <div className="stat-item">
          <img src={img4} alt=""></img>
        </div>
        <div className="stat-item">
          <img src={img5} alt=""></img>
        </div>
      </section>
    </div>
  );
};

export default AboutUsWithStats;
