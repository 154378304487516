import React from 'react'
import Services from './Services'
import ServicesCards from './ServicesCards'
function ParentServies() {
  return (
    <>
    <Services/>
    <ServicesCards/>
    </>
  )
}

export default ParentServies
