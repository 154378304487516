import React, { useState } from "react";
import { Link } from "react-router-dom";
import img1 from "../image/1942.png";
import img2 from "../image/1943.png";
import img3 from "../image/2149241261.png";
import img4 from "../image/1944.png";
import img5 from "../image/1950.png";
import img6 from "../image/1948.png";
import img7 from "../image/1949.png";
import img8 from "../image/1945.png";
import img9 from "../image/1947.png";
import img10 from "../image/1946.png";
import image2 from "../image/All Icons/Group 1023.png";
import image3 from "../image/All Icons/Group 1024.png";
import image4 from "../image/All Icons/Group 1025.png";
import image5 from "../image/All Icons/Group 1025.png";
import image6 from "../image/All Icons/Group 1027.png";
function ServicesCards() {
  const [services, setServices] = useState([
    // {
    //   title: "CONSTRUCTION CONSULTANCY",
    //   description: "Expert guidance for every stage of your construction project, ensuring optimal results from start to finish.",
    //   imageUrl: img1,
    //   imageUrlSub: image1,
    //   link: "/ourServicesDetails"
    // },
    {
      title: "ARCHITECTURE & INTERIOR DES...",
      description:
        "Innovative and functional design solutions that bring your vision to life, tailored to your unique needs.",
      imageUrl: img2,
      imageUrlSub: image2,
      link: "/architectureInterior",
    },
    {
      title: "STRUCTURAL ENGINEERING",
      description:
        "Safe and efficient structural designs using advanced techniques and materials for long-lasting stability.",
      imageUrl: img3,
      imageUrlSub: image3,
      link: "/structuralEngineering",
    },
    {
      title: "REAL ESTATE PROJECTS",
      description:
        "Comprehensive management of real estate developments, from land acquisition to project completion, maximizing value at every step.",
      imageUrl: img4,
      imageUrlSub: image4,
      link: "/realEstate",
    },
    {
      title: "PROJECT MANAGEMENT",
      description:
        "Meticulous planning and execution of projects to ensure they are completed on time, within budget, and to the highest standards.",
      imageUrl: img5,
      imageUrlSub: image5,
      link: "/projectManagement",
    },
    {
      title: "DESIGN + BUILD",
      description:
        "Seamless integration of design and construction phases for streamlined project delivery, reducing risks and costs.",
      imageUrl: img6,
      imageUrlSub: image6,
      link: "/designBuild",
    },
    {
      title: "VALUATION",
      description:
        "Accurate and reliable property valuations, helping you make informed decisions about your real estate assets.",
      imageUrl: img7,
      imageUrlSub: image6,
      link: "/valuation",
    },
    {
      title: "URBAN PLANNING",
      description:
        "Creating sustainable and livable communities through strategic land use and comprehensive urban development plans.",
      imageUrl: img8,
      imageUrlSub: image6,
      link: "/urbanPlanning",
    },
    {
      title: "INFRASTRUCTURE",
      description:
        "Robust and sustainable infrastructure solutions, supporting the development and maintenance of essential public and private sector projects.",
      imageUrl: img9,
      imageUrlSub: image6,
      link: "/infrastructure",
    },
    {
      title: "LANDSCAPING",
      description:
        "Transforming outdoor spaces into beautiful and functional environments with creative and sustainable landscaping solutions.",
      imageUrl: img10,
      imageUrlSub: image6,
      link: "/landscaping",
    },
    // {
    //   title: "SURVEY SOLUTIONS",
    //   description: "Precise and reliable survey services using advanced technology to ensure accurate data collection.  ",
    //   imageUrl: img1,
    //   imageUrlSub: image6,
    //   link: "/surveySolutions"
    // },
    // {
    //   title: "CONSTRUCTION CONSULTANCY 4",
    //   description: "It is a long established fact that a reader will be distracted by the readable content of a page layout loren ipsum",
    //   imageUrl: img1,
    //   imageUrlSub: image6,
    //   // link: "/construction-consultancy-4"
    // },
  ]);

  const [showMore, setShowMore] = useState(false);

  const visibleServices = showMore ? services : services.slice(0, 6);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  return (
    <div className="">
      <h3 className="text-lg text-center mt-10">Our Services</h3>
      <h2 className="text-4xl font-bold mt-4 text-center">PROVIDING SOLUTION OF EVERY KIND</h2>
      <div className="flex flex-wrap gap-10 justify-center items-center cards-services1">
        {visibleServices.map((service, index) => (
          <div
            key={index}
            className="relative gap-4 flex flex-col mt-6 text-gray-700 bg-white shadow-md bg-clip-border rounded-xl p-2 w-72"
          >
            <div className="relative h-56 -mt-12 overflow-hidden text-white shadow-lg bg-clip-border rounded-xl bg-blue-gray-500 shadow-blue-gray-500/40">
              <div>
                <img
                  className="img-services-cards"
                  src={service.imageUrl}
                  alt="img"
                />
              </div>
            </div>
            <div className="absolute right-6 top-40">
              {/* <img src={service.imageUrlSub} alt="img" className="w-10 h-10 sub-image" /> */}
            </div>
            <h3 className="block leading-snug tracking-normal text-blue-gray-900">
              {service.title}
            </h3>
            <p className="block antialiased font-light leading-relaxed text-inherit h-20">
              {service.description}
            </p>
            <Link to={service.link} className="text-[#34438c] ">
              More Info
              <span className="bg-white text-black px-2">
                <i className="fa-solid fa-arrow-right"></i>
              </span>
            </Link>
          </div>
        ))}
        {!showMore && (
          <button className="w-full" onClick={toggleShowMore}>
            View More
            <span className="bg-white text-black px-2">
              <i className="fa-solid fa-arrow-right"></i>
            </span>
          </button>
        )}

        {showMore && (
          <button className="w-full" onClick={toggleShowMore}>
            Hide
            <span className="bg-white text-black px-2">
              <i className="fa-solid fa-arrow-right"></i>
            </span>
          </button>
        )}
      </div>
    </div>
  );
}

export default ServicesCards;
