import React from 'react';
import './HomeStyle.css'; // Link to CSS file for styling
import img from "../image/logo.d242736293aa589515d3.png";
import img1 from "../image/KTRC-LOGO-1 1.png";
import img2 from "../image/Group 1144.png";
import img3 from "../image/ktrcagro-02 1.png";
import img4 from "../image/ktrcsk 8.png";
import img5 from "../image/KTRC (1) (1) 1.png";
import img6 from "../image/Final Blue Logo 1.png";
import img7 from "../image/KSKILL (2) 1.png";

const KTRCGroup = () => {
  return (
    <div className="ktrc-group">
      <h3 className="text-lg text-center">Group</h3>
      <h2>Our KTRC Group</h2>
      <div className="flex items-center">
        <div className="logo-grid">
          <div className="flex items-center justify-center logo-item">
            <img src={img} alt="Logo 1" />
            <a className="link-style" href="https://ksvsofttech.com/" target="_blank" rel="noopener noreferrer">
              https://ksvsofttech.com/
            </a>
          </div>
          <div className="flex items-center justify-center logo-item">
            <img src={img1} alt="Logo 2" />
            <a className="link-style" href="https://ktrc3dvision.com/" target="_blank" rel="noopener noreferrer">
              https://ktrc3dvision.com/
            </a>
          </div>
          <div className="flex items-center justify-center logo-item">
            <img src={img2} alt="Logo 3" />
            <a className="link-style" href="https://www.ktrces.com/" target="_blank" rel="noopener noreferrer">
              https://www.ktrces.com/
            </a>
          </div>
          <div className="flex items-center justify-center logo-item">
            <img src={img3} alt="Logo 4" />
            <a className="link-style" href="https://example-link4.com" target="_blank" rel="noopener noreferrer">
              Visit Link
            </a>
          </div>
          <div className="flex items-center justify-center logo-item">
            <img src={img4} alt="Logo 4" />
            <a className="link-style" href="https://example-link4.com" target="_blank" rel="noopener noreferrer">
              Visit Link
            </a>
          </div>
          <div className="flex items-center justify-center logo-item">
            <img src={img5} alt="Logo 4" />
            <a className="link-style" href="https://example-link4.com" target="_blank" rel="noopener noreferrer">
              Visit Link
            </a>
          </div>
          <div className="flex items-center justify-center logo-item">
            <img src={img6} alt="Logo 4" />
            <a className="link-style" href="https://example-link4.com" target="_blank" rel="noopener noreferrer">
              Visit Link
            </a>
          </div>
          <div className="flex items-center justify-center logo-item">
            <img src={img7} alt="Logo 4" />
            <a className="link-style" href="https://example-link4.com" target="_blank" rel="noopener noreferrer">
              Visit Link
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KTRCGroup;
