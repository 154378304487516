import React, { useEffect } from "react";
import img1 from "../image/Group.png";
import img2 from "../image/Group 2201980.png";
function RealEstate() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <section className="w-full h-[50vh] flex flex-col justify-center items-center relative">
        <div className="background-overlay absolute inset-0 px-4">
          <div id="ServicesbgImages"></div>
        </div>
        <div className="w-11/12 md:w-3/4 lg:w-2/3 xl:w-9/12 flex flex-col justify-center items-center gap-4 z-10 relative">
          <h3 className="text-white border-bottom-h3">Service details</h3>
          <h4 className="text-services lg:text-3xl md:text-3xl text-white">
            Our Service Details
          </h4>
          <button className="flex button-servies-details gap-2">
            <span className="text-stone-600 text-white">Home</span>
            <span>
              <i class="fa-solid fa-angle-right"></i>
            </span>
            <span>Our Services</span>
            <span>
              <i class="fa-solid fa-angle-right"></i>
            </span>
            <span>Our Services Details</span>
          </button>
        </div>
      </section>
      <section className="flex flex-wrap justify-center p-10 gap-10">
      <div className="relative flex flex-col bg-clip-border rounded-xl shadow-md w-full max-w-[20rem] p-4">
          <h3 className="text-2xl mb-2">The Service Overview</h3>
          <p className=" mb-4">
            Our team manages all aspects of real estate development, including
            market analysis, site selection, project financing, and construction
            management. We aim to deliver projects that meet market demands and
            exceed client expectations.
          </p>
          <h3 className="text-xl mb-2">Specialty in Service</h3>
          <div>
            <div className="flex gap-4 pb-2 items-center">
              <span>
                <img className="w-5" src={img1} alt="img"></img>
              </span>
              <p>Comprehensive market analysis and site selection</p>
            </div>
            <div className="flex gap-4 pb-2 items-center">
              <span>
                <img className="w-4" src={img1} alt="img"></img>
              </span>
              <p>Strategic project planning and financing</p>
            </div>
            <div className="flex gap-4 pb-2 items-center">
              <span>
                <img className="w-4" src={img1} alt="img"></img>
              </span>
              <p>Efficient construction management</p>
            </div>
            <div className="flex gap-4 pb-2 items-center">
              <span>
                <img className="w-4" src={img1} alt="img"></img>
              </span>
              <p>High ROI-focused development strategies</p>
            </div>
            <div className="flex gap-4 pb-2 items-center">
              <span>
                <img className="w-4" src={img1} alt="img"></img>
              </span>
              <p>Extensive industry network and partnerships</p>
            </div>
          </div>
          {/* <button
            className="flex items-center bg-[#373D72] justify-between select-none font-sans font-bold text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-sm py-1 px-7 rounded-lg text-blue-gray-900 shadow-md shadow-blue-gray-500/10 hover:shadow-lg hover:shadow-blue-gray-500/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none w-full hover:scale-[1.02] focus:scale-[1.02] active:scale-100"
            type="button"
          >
            <span>
              <i class="fa-regular fa-file-pdf text-white"></i>
            </span>
            <p>Download Our Brochure</p>
            <span>
              <i class="fa-solid fa-download text-white"></i>
            </span>
          </button> */}
        </div>
        <div className="flex flex-col justify-center items-center">
          <div className="max-w-lg w-full">
            <div className="relative">
              <img src={img2} alt="Mountain" className="relative w-full" />
              {/* <div className="absolute bottom-0 services-construction">
                <span>
                  <i class="fa-solid fa-user text-white"></i>
                </span>
                <h3 className="text-sm text-white">REAL ESTATE PROJECTS</h3>
              </div> */}
            </div>
            <div className="p-6">
              <h3 className="text-2xl mb-2">Description of our service</h3>
              <p className=" mb-4">
                KTRC Engineering's real estate project services cover the entire
                spectrum of development, from land acquisition to project
                completion. We ensure that every real estate venture is
                strategically planned and executed to maximize value.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default RealEstate;
