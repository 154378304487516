import React, { useState, useEffect } from 'react';
import img from "../image/Group 2201758.png";
import img1 from "../image/Group 2201974.png";
import img2 from "../image/Group 2201975.png";
import img3 from "../image/Group 2201976 (1).png";

const ImageSlider = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isHovered, setIsHovered] = useState(false);

  const slides = [
    {
      image: img,
      title: 'COMPLETE SOLUTION PACKAGE DESIGN SERVICES',
      description: 'KTRC translates client’s vision, brand and corporate culture into inspiration interior environments.',
    },
    {
      image: img1,
      title: 'CONSTRUCTION BUSINESS',
      description: 'KTRC translates client’s vision, brand and corporate culture into inspiration interior environments.',
    },
    {
      image: img2,
      title: 'WE HAVE INTERIOR',
      description: 'KTRC translates client’s vision, brand and corporate culture into inspiration interior environments.',
    },
    {
      image: img3,
      title: 'WE BELIVE THAT, DESIGN IS NOT DECORATION!',
      description: 'KTRC translates client’s vision, brand and corporate culture into inspiration interior environments.',
    },
  ];

  // Autoplay functionality
  useEffect(() => {
    const interval = setInterval(() => {
      if (!isHovered) {
        nextSlide();
      }
    }, 3000); // Change slide every 5 seconds
    return () => clearInterval(interval);
  }, [isHovered]);

  const nextSlide = () => {
    console.log("Next Slide Clicked");
    setCurrentSlide((prev) => {
      console.log("Current Slide Before Next:", prev);
      const newSlide = prev === slides.length - 1 ? 0 : prev + 1;
      console.log("Current Slide After Next:", newSlide);
      return newSlide;
    });
  };

  const prevSlide = () => {
    console.log("Previous Slide Clicked");
    setCurrentSlide((prev) => {
      console.log("Current Slide Before Prev:", prev);
      const newSlide = prev === 0 ? slides.length - 1 : prev - 1;
      console.log("Current Slide After Prev:", newSlide);
      return newSlide;
    });
  };

  const handleHover = (hoverState) => {
    setIsHovered(hoverState);
  };

  return (
    <div
      className="relative w-full bg-gray-200 overflow-hidden slider-border slider-res"
      onMouseEnter={() => handleHover(true)}
      onMouseLeave={() => handleHover(false)}
    >
      {/* Slide Image */}
      <img
        src={slides[currentSlide].image}
        alt={slides[currentSlide].title}
        className="w-full h-full object-cover"
      />

      {/* Slide Content */}
      <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col items-start justify-center px-10 lg:px-28">
        <h1 className="text-white text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold mb-4">
          {slides[currentSlide].title}
        </h1>
        <p className="text-white text-lg max-w-lg">
          {slides[currentSlide].description}
        </p>
      </div>

      {/* Number List (Right-side) */}
      <div className="absolute right-8 top-1/2 transform -translate-y-1/2 flex flex-col space-y-4 text-white text-lg">
        {slides.map((_, index) => (
          <span
            key={index}
            className={`cursor-pointer transition-all duration-300 ${
              index === currentSlide ? 'text-blue-500' : 'text-white'
            }`}
            onClick={() => setCurrentSlide(index)}
          >
            {`0${index + 1}`} {/* Shows 01, 02, 03, 04 */}
          </span>
        ))}
      </div>

      {/* Slider Controls (Bottom-right corner) */}
      <div className="absolute bottom-8 right-8 flex space-x-4 items-center z-10">
        <button
          className="slider-button p-2 border border-gray-400 rounded-full"
          onClick={prevSlide}
        >
          &larr;
        </button>
        <div className="flex space-x-2">
          {slides.map((_, index) => (
            <span
              key={index}
              className={`h-2 w-2 rounded-full ${
                index === currentSlide ? 'bg-blue-500' : 'bg-gray-400'
              }`}
            ></span>
          ))}
        </div>
        <button
          className="slider-button p-2 border border-gray-400 rounded-full"
          onClick={nextSlide}
        >
          &rarr;
        </button>
      </div>
    </div>
  );
};

export default ImageSlider;
