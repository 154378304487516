import React from 'react'
import CourierHome from "./CourierHome"
import CourierOpenings from './CourierOpenings'

function Courires() {
  return (
    <>
    <CourierHome/>
    <CourierOpenings/>
    </>
  )
}

export default Courires