import React from "react";

const MissionVision = () => {
  return (
    <div className="p-2 md:p-10"> {/* Outer div with padding */}
      <div className="mission-image text-white py-14 px-5 rounded-xl">
        <h2 className="text-center text-3xl font-bold mb-8">OUR MISSION & VISION</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 xl:px-16 cursor-pointer">
          {/* Mission Section */}
          <div className="bg-white text-black p-6 rounded-lg transition duration-300 hover:bg-black hover:text-white">
            <h3 className="text-2xl font-bold mb-4">MISSION</h3>
            <p>
              Our mission is to deliver excellence in all aspects of our business,
              from customer service to product innovation, while creating a positive impact on
              our nation. We are committed to providing the highest quality solutions that meet
              the needs and exceed the expectations of our customers. Our focus on ethical
              business practices and social responsibility ensures that we contribute to the
              sustainable growth and development of India.
            </p>
          </div>
          {/* Vision Section */}
          <div className="bg-white text-black p-6 rounded-lg transition duration-300 hover:bg-black hover:text-white">
            <h3 className="text-2xl font-bold mb-4">VISION</h3>
            <p>
              Our vision is to be the most trusted and innovative group of companies in India,
              known for our commitment to customer satisfaction and driving positive change in
              our nation. We will achieve this by delivering exceptional products and services
              in the sectors of technology and education.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MissionVision;
