import React from "react";
import { Link, useLocation } from "react-router-dom";

const NavItems = ({
  name,
  handleNavItemClick,
  children,
  isHoveredItem,
  handleHoveredItem,
}) => {
  const location = useLocation();
  const isActive =
    (location.pathname === "/" && (name === "home" || name === "AboutUs")) ||
    location.pathname === `/${name}`;
  const isHovered = name === isHoveredItem;
  return (
    <li
      className=" max-lg:py-3 lg:px-5 px-1 relative font-roboto-condensed "
      onClick={() => {
        if (children !== "Courses") {
          handleNavItemClick(name);
        }
      }}
      onMouseEnter={() => handleHoveredItem(name)}
      onMouseLeave={() => handleHoveredItem("")}
    >
      <a
        id="text-font"
        className={`hover:text-[#373D72] hover:cursor-pointer ${
          isActive || isHovered ? "text-[#373D72]" : ""
        }  block text-[20px] `}
      >
        {children !== "Courses" ? (
          children
        ) : (
          <div className="relative !py-0  inline-block text-left">
            <div className="group ">
              <Link to={"/civil"}>
                <a
                  className={`hover:text-[#373D72]  ${
                    isActive || isHovered ? "text-[#373D72]" : ""
                  } hover:cursor-pointer block text-[16px]`}
                >
                  Courses
                </a>
              </Link>
            </div>
          </div>
        )}
      </a>
      {(isActive || isHovered) && (
        <div className="active-line absolute w-full h-1 bg-[#373D72] bottom-0"></div>
      )}
    </li>
  );
};

export default NavItems;
