import React, { useState } from "react";
import Slider from "react-slick";
import { FaPlus, FaMinus } from "react-icons/fa";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import img from "../image/Group 2201751 (1).png";
import img1 from "../image/Ellipse 76.png";
import img2 from "../image/Ellipse 77.png";
import img3 from "../image/Ellipse 78.png";
import img4 from "../image/Ellipse 79.png";
import img5 from "../image/Ellipse 80.png";
import img6 from "../image/Ellipse 56.png";

const TestimonialSection = () => {
  const [selectedTestimonial, setSelectedTestimonial] = useState(null);

  const testimonials = [
    {
      name: "Neeraj Patidar",
      username: "@neerajpatidar",
      review:
        "Positive work environment, supportive senior staff, and friendly colleagues. The opportunity to learn and explore new knowledge every day has been a source of pride for me.",
      image: img1,
    },
    {
      name: "Shivam Kumar Gupta",
      username: "@ShivamKumarGupta",
      review:
        "KSVSoftTech is truly one of the best organizations in Indore. If you want to enjoy learning while working, this is the place for you. It's a really good place to learn, with an open culture and positive environment. There are lots of opportunities to learn new tools and technologies. also, the senior management is incredibly helpful and always willing to share their knowledge. Highly recommended for anyone seeking continuous growth in their career.",
      image: img2,
    },
    {
      name: "Nidhi Rahangdale",
      username: "@NidhiRahangdale",
      review:
        "KSVSoftTech is the best software development company in Indore with a great work environment that allows us to be productive and grow. Additionally, we get to learn new skills. It's a friendly work culture here, and everyone encourages you to do your best.",
      image: img3,
    },
    {
      name: "Bhavesh Pathe",
      username: "@BhaveshPathe",
      review:
        "I'm really happy to share my experience working at KSV SoftTech, the top software development company in Indore. It's been amazing so far! The team here always encourages me to go the extra mile with fun activities and helpful sessions. And whenever I need help, the senior developers have always got my back.",
      image: img4,
    },
    {
      name: "Satish Muwel",
      username: "@SatishMuwel",
      review:
        "A great company with very good opportunities. The working environment is excellent, giving developers the freedom they need to work with a peaceful mind, no doubt the best software development company in Indore.",
      image: img5,
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 5,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    arrows: false,
    pauseOnHover: true,
  };

  return (
    <div className="flex flex-col lg:flex-row items-start justify-between p-6 lg:p-12 bg-gray-100 gap-8">
      <div className="lg:w-1/2 w-full mb-8 lg:mb-0">
        <img
          src={img}
          alt="Testimonial Background"
          className="w-full h-auto rounded-lg shadow-lg object-cover"
        />
      </div>

      <div className="lg:w-1/2 w-full">
        <Slider {...settings}>
          {testimonials.map((testimonial, index) => (
            <div key={index} className="relative p-2">
              <div
                onClick={() => setSelectedTestimonial(selectedTestimonial === index ? null : index)}
                className="flex flex-col p-4 cursor-pointer hover:bg-[#34438C] bg-black text-white rounded-lg shadow-lg transition-all duration-300 relative overflow-hidden"
              >
                <div className="absolute bottom-0 right-0 w-28 h-28 bg-gray-100 rounded-full transform translate-x-1/2 translate-y-1/2"></div>

                {selectedTestimonial === index ? (
                  <FaMinus className="absolute bottom-2 right-2 text-[#34438C] w-8 h-8 z-10" />
                ) : (
                  <FaPlus className="absolute bottom-2 right-2 text-[#34438C] w-8 h-8 z-10" />
                )}

                {selectedTestimonial === index && (
                  <div className="mb-2 text-blue-400">
                    <p className="text-sm lg:text-sm">{testimonial.review}</p>
                  </div>
                )}

                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <img
                      src={testimonial.image}
                      alt={testimonial.name}
                      className="w-10 h-10 rounded-full mr-4"
                    />
                    <div>
                      <h4 className="text-white">{testimonial.name}</h4>
                      <p>{testimonial.username}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default TestimonialSection;
  