import React, { useState } from "react";
import img1 from "../image/1943.png";
import img2 from "../image/1944.png";
import img3 from "../image/1945.png";
import img4 from "../image/1948.png";
import img5 from "../image/1947.png";
import "./Carrer.css";
const CareerOpenings = () => {
  const [selectedFilter, setSelectedFilter] = useState("All");
  const [isModalOpen, setModalOpen] = useState(false);
  const [jobToApply, setJobToApply] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    dob: "",
    email: "",
    basicGraduation: "",
    postGraduation: "",
    doctorate: "",
    gender: "",
    state: "",
    phoneNumber: "",
    file: null,
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleFileChange = (e) => {
    setFormData({
      ...formData,
      file: e.target.files[0],
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add form submission logic here
    console.log("Form Data Submitted:", formData);
  };
  const jobs = [
    {
      title: "Interior Designer",
      location: "Indore",
      experience: "5 years",
      salary: "₹35,000",
      deadline: "20 Jan 2025",
      category: "Interior Designer",
      image: img1, // Assigning a specific image
    },
    {
      title: "Architecture",
      location: "Bhopal",
      experience: "4 years",
      salary: "₹45,000",
      deadline: "15 Feb 2025",
      category: "Architecture",
      image: img2, // Assigning a different image
    },
    {
      title: "Architecture",
      location: "Bhopal",
      experience: "4 years",
      salary: "₹45,000",
      deadline: "15 Feb 2025",
      category: "Architecture",
      image: img3, // Assigning a different image
    },
    {
      title: "Construction",
      location: "Bhopal",
      experience: "4 years",
      salary: "₹45,000",
      deadline: "15 Feb 2025",
      category: "Construction",
      image: img4, // Assigning a different image
    },
    {
      title: "Survey",
      location: "Bhopal",
      experience: "4 years",
      salary: "₹45,000",
      deadline: "15 Feb 2025",
      category: "Survey",
      image: img5, // Assigning a different image
    },
    // Add more jobs with different images
  ];

  const filteredJobs =
    selectedFilter === "All"
      ? jobs
      : jobs.filter((job) => job.category === selectedFilter);

  const openModal = (job) => {
    setModalOpen(true);
    setJobToApply(job);
  };

  const closeModal = () => {
    setModalOpen(false);
    setJobToApply(null);
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100">
      <div className="w-full max-w-7xl p-5">
        <div className="text-center mb-12">
          <h3 className="text-lg">Come Join Us</h3>
          <h2 className="text-4xl font-bold mt-4">CAREER OPENINGS</h2>
          <p className="text-gray-500 mt-6 max-w-2xl mx-auto text-center">
            We're always looking for creative , talented self-starters to join
            the KTRC Group.
          </p>
          <p className="text-gray-500 max-w-2xl mx-auto text-center">
            Check out our open roles below and fill out an application
          </p>
        </div>

        <form
          onSubmit={handleSubmit}
          className="p-6 bg-white shadow-md rounded-lg w-full max-w-full"
        >
          <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Your Name
              </label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                placeholder="Enter your name"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Gender
              </label>
              <select
                name="gender"
                value={formData.gender}
                onChange={handleChange}
                className="mt-1 block w-full border border-gray-300 rounded-md p-2"
              >
                <option value="">Select..</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Date of Birth
              </label>
              <input
                type="date"
                name="dob"
                value={formData.dob}
                onChange={handleChange}
                className="mt-1 block w-full border border-gray-300 rounded-md p-2"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                State
              </label>
              <select
                name="state"
                value={formData.state}
                onChange={handleChange}
                className="mt-1 block w-full border border-gray-300 rounded-md p-2"
              >
                <option value="">Select..</option>
                <option value="State 1">State 1</option>
                <option value="State 2">State 2</option>
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                E-mail
              </label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                placeholder="Enter your email"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Phone Number
              </label>
              <input
                type="text"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
                className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                placeholder="Enter your phone number"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Basic / Graduation
              </label>
              <select
                name="basicGraduation"
                value={formData.basicGraduation}
                onChange={handleChange}
                className="mt-1 block w-full border border-gray-300 rounded-md p-2"
              >
                <option value="">Select..</option>
                <option value="Basic">Basic</option>
                <option value="Graduation">Graduation</option>
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Post Graduation
              </label>
              <select
                name="postGraduation"
                value={formData.postGraduation}
                onChange={handleChange}
                className="mt-1 block w-full border border-gray-300 rounded-md p-2"
              >
                <option value="">Select..</option>
                <option value="Post Grad">Post Grad</option>
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Doctorate / PhD
              </label>
              <select
                name="doctorate"
                value={formData.doctorate}
                onChange={handleChange}
                className="mt-1 block w-full border border-gray-300 rounded-md p-2"
              >
                <option value="">Select..</option>
                <option value="Doctorate">Doctorate</option>
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Upload CV
              </label>
              <input
                type="file"
                name="file"
                onChange={handleFileChange}
                className="mt-1 block w-full border border-gray-300 rounded-md p-2"
              />
            </div>
          </div>
          <div className="flex justify-end">
            <button
              type="submit"
              className="mt-4 w-fit bg-[#34438C] text-white p-2 rounded-md"
            >
              Submit
            </button>
          </div>
        </form>
        {/* Filter buttons */}
        {/* <div className="flex items-center flex-col">
          <div className="flex flex-wrap justify-left space-x-4 mb-6">
            <button
              onClick={() => setSelectedFilter("All")}
              className={`px-4 ${selectedFilter === "All" ? "button-2" : ""}`}
            >
              All
            </button>
            <button
              onClick={() => setSelectedFilter("Interior Designer")}
              className={`px-4 ${
                selectedFilter === "Interior Designer" ? "button-2" : ""
              }`}
            >
              Interior Designer
            </button>
            <button
              onClick={() => setSelectedFilter("Architecture")}
              className={`px-4 ${
                selectedFilter === "Architecture" ? "button-2" : ""
              }`}
            >
              Architecture
            </button>
            <button
              onClick={() => setSelectedFilter("Construction")}
              className={`px-4 ${
                selectedFilter === "Construction" ? "button-2" : ""
              }`}
            >
              Construction
            </button>
            <button
              onClick={() => setSelectedFilter("Survey")}
              className={`px-4 ${
                selectedFilter === "Survey" ? "button-2" : ""
              }`}
            >
              Survey
            </button>
          </div>
          <div className="grid">
            {filteredJobs.map((job, index) => (
              <div
                key={index}
                className="flex flex-col md:flex-row p-2 w-full mb-4 career-cards gap-8"
              >
                <div className="flex-shrink-0">
                  <h2 className="text-xl font-semibold mb-2 md:mb-0">
                    {job.title}
                  </h2>
                  <img
                    src={job.image}
                    alt={job.title}
                    className="mb-4 w-48 h-auto career-img"
                  />
                </div>
                <div>
                  <p>Full Time</p>
                  <p>Location: {job.location}</p>
                  <p className="max-w-3xl">
                    Morbi vel ex rhoncus purus tincidunt finibus. Aliquam in
                    neque nibh. Aenean non nisi ac urna convallis fermentum nec
                    ut leo.
                  </p>
                  <ul className="flex career-top-border justify-between items-baseline mt-4">
                    <p>Experience: {job.experience}</p>
                    <p>Salary: {job.salary}</p>
                    <p>Deadline: {job.deadline}</p>
                    <button
                      className="mt-4 button-carrer"
                      onClick={() => openModal(job)}
                    >
                      Apply
                    </button>
                  </ul>
                </div>
              </div>
            ))}
          </div>
        </div> */}
      </div>

      {/* Modal for applying */}
      {/* {isModalOpen && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
            <h2 className="text-xl font-bold mb-4">
              Apply for {jobToApply.title}
            </h2>
            <form>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  Name
                </label>
                <input type="text" className="w-full p-2 border rounded" />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  Email
                </label>
                <input type="email" className="w-full p-2 border rounded" />
              </div>
              <button
                type="submit"
                className="bg-blue-500 text-white px-4 py-2 rounded"
              >
                Submit
              </button>
              <button
                type="button"
                onClick={closeModal}
                className="ml-4 text-red-500"
              >
                Cancel
              </button>
            </form>
          </div>
        </div>
      )} */}
    </div>
  );
};

export default CareerOpenings;
