import React from "react";
import "./footer.css"
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll";
import img1 from "../image/Group (6).png"
import img2 from "../image/Group (7).png"
import img3 from "../image/Group (8).png"
import img4 from "../image/Group (9).png"
import img5 from "../image/Group 1238.png"
import img6 from "../image/Group 2201726.png"
import img7 from "../image/Group 1220.png"
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
const Footer = () => {
  const navigate = useNavigate();

  const scrollToTop = () => {
    scroll.scrollToTop();
  };
  return (
    <footer className="bg-black bg-footer text-white py-12">
      <div className="container mb-10 mx-auto px-6 lg:px-16 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 ">
        {/* Where to Find Us Section */}
        <div>
          <h2 className="text-lg font-bold mb-6">WHERE TO FIND US</h2>
          <div className="space-y-2">
            <div className="flex items-center space-x-2">
              <img src={img5} alt="" className="w-4"></img>
              <span>+917389424444</span>
              <span>+917389424444</span>
            </div>
            <div className="flex items-center space-x-2">
            <img src={img6} alt="" className="w-4"></img>
              <span>ktrcengineeringservices@gmail.com</span>
            </div>
            <div className="flex items-baseline space-x-2">
            <img src={img7} alt="" className="w-4"></img>
              <span>
                201, Labbaik Regency, Near Navneet Plaza, <br />
                Old Palasia Indore, Madhya Pradesh - 452001
              </span>
            </div>
          </div>
        </div>

        {/* Service List Section */}
        <div>
          <h2 className="text-lg font-bold mb-6">SERVICE LIST</h2>
          <ul className="grid grid-cols-2 gap-2">
            <Link className="text-xs" to="/landscaping">Landscaping</Link>
            <Link className="text-xs" to="/Valuation">Valuation</Link>
            <Link className="text-xs" to="/Infrastructure">Infrastructure</Link>
            <Link className="text-xs" to="/designBuild">Design + Build</Link>
            <Link className="text-xs" to="/realEstate">Real Estate Projects</Link>
            <Link className="text-xs" to="/urbanPlanning">Urban Planning</Link>
            {/* <Link className="text-xs" to="/">Construction Consultancy</Link> */}
            <Link className="text-xs" to="/projectManagement">Project Management</Link>
            <Link className="text-xs" to="/architectureInterior">Architecture & Interior Design</Link>
            <Link className="text-xs" to="/structuralEngineering">Structural Engineering</Link>
          </ul>
        </div>

        {/* About Us and Social Media Section */}
        <div>
          {/* About Us Section */}
          <div className="mb-6">
            <h2 className="text-lg font-bold mb-6">ABOUT US</h2>
            <ul className="space-y-2">
              <li>KTRC Group</li>
              <li>Director Message</li>
              <li>Our KTRC Branches</li>
            </ul>
          </div>

          {/* Social Media Section */}
          <div>
            <h2 className="text-lg font-bold mb-6">SOCIAL MEDIA</h2>
            <div className="flex space-x-4 mb-12 md:mb-0">
              <a href="#" aria-label="Facebook">
                <img src={img1} alt="Facebook" className="w-8 h-8" />
              </a>
              <a href="#" aria-label="LinkedIn">
                <img src={img2} alt="LinkedIn" className="w-8 h-8" />
              </a>
              <a href="#" aria-label="YouTube">
                <img src={img3} alt="YouTube" className="w-8 h-8" />
              </a>
              <a href="#" aria-label="Instagram">
                <img src={img4} alt="Instagram" className="w-8 h-8" />
              </a>
            </div>
          </div>
        </div>

      </div>
      {/* Copyright section */}
      <div className="absolute bottom-0 w-full text-white min-h-12 flex flex-wrap items-center justify-center text-[14px] bg-[#212121] border-top-footer text-center">
          <ul className="lg:w-1/2 flex flex-wrap gap-2 items-center justify-center">
            <li className="text-sm text-justify border-bottom-footer">
              <Link to="/" onClick={scrollToTop}>
                Home
              </Link>
            </li>
            <li className="text-sm text-justify border-bottom-footer">
              <Link
                to="aboutUs"
                smooth={true}
                duration={500}
                onClick={scrollToTop}
              >
                About Us
              </Link>
            </li>
            <li className="text-sm text-justify border-bottom-footer">
              <Link
                to="/ourServices"
                smooth={true}
                duration={500}
                onClick={scrollToTop}
              >
                Our Services
              </Link>
            </li>
            <li className="text-sm text-justify border-bottom-footer">
              <Link
                to="/#ourMissionAndVision"
                smooth={true}
                duration={500}
                onClick={scrollToTop}
              >
                Our Mission & Vision
              </Link>
            </li>
            {/* <li className="text-sm text-justify border-bottom-footer">
              <Link
                to="/#ourMissionAndVision"
                smooth={true}
                duration={500}
                onClick={scrollToTop}
              >
                Our Vision
              </Link>
            </li> */}
            <li className="text-sm text-justify border-bottom-footer">
              <Link
                to="career"
                smooth={true}
                duration={500}
                onClick={scrollToTop}
              >
                Career
              </Link>
            </li>
            <li className="text-sm text-justify border-bottom-footer">
              <Link
                to="contactUs"
                smooth={true}
                duration={500}
                onClick={scrollToTop}
              >
                Contact Us
              </Link>
            </li>
          </ul>
          <div className="lg:w-1/2">
            All Rights Reserved. © 2024 Design & Developed By
            <a
              href="https://ksvsofttech.com/"
              className="text-white"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span> KSVSoftTech</span>
            </a>
          </div>
        </div>
    </footer>
  );
};

export default Footer;
