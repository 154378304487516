import React, { useState, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules"; // Import Autoplay module
import "swiper/css"; // Main Swiper styles
import "swiper/css/navigation"; // For Navigation styles
import img from "../image/architecture & interior designing (1).png";
import img1 from "../image/Real Estate Projects.png";
import img2 from "../image/Design + Build.png";
import img3 from "../image/Group 2201725.png";
import img4 from "../image/Infrastructure.png";
import img5 from "../image/Landscaping.png";
import img6 from "../image/structural engineering.png";
import img7 from "../image/Urban Planning.png";
import img8 from "../image/Valuation.png";
const PortfolioSlider = () => {
  const [activeIndex, setActiveIndex] = useState(0); // State to track the active slide
  const swiperRef = useRef(null); // Reference for the Swiper instance
  const projects = [
    {
      year: "2023",
      title: "The Beach House",
      category: "Interior",
      image: img,
    },
    {
      year: "2020",
      title: "The Beach House",
      category: "Interior",
      image: img1,
    },
    {
      year: "2023",
      title: "The Beach House",
      category: "Interior",
      image: img2,
    },
    {
      year: "2020",
      title: "The Beach House",
      category: "Interior",
      image: img3,
    },
    {
      year: "2020",
      title: "The Beach House",
      category: "Interior",
      image: img4,
    },
    {
      year: "2020",
      title: "The Beach House",
      category: "Interior",
      image: img5,
    },
    {
      year: "2020",
      title: "The Beach House",
      category: "Interior",
      image: img6,
    },
    {
      year: "2020",
      title: "The Beach House",
      category: "Interior",
      image: img7,
    },
    {
      year: "2020",
      title: "The Beach House",
      category: "Interior",
      image: img8,
    },
  ];

  const settings = {
    modules: [Navigation, Autoplay], // Include Autoplay module
    spaceBetween: 30,
    slidesPerView: 4, // Show 4 slides by default
    navigation: false, // Disable default navigation
    autoplay: {
      delay: 3000, // Delay between slide changes (in milliseconds)
      disableOnInteraction: false, // Keep autoplay running after interaction
    },
    onSlideChange: (swiper) => {
      setActiveIndex(swiper.activeIndex); // Update active slide index
    },
    breakpoints: {
      // Mobile screens
      320: {
        slidesPerView: 1, // Ensure that 1 card is displayed on small mobile screens
        spaceBetween: 10,
      },
      // Small tablets
      640: {
        slidesPerView: 1, // Ensure that 1 card is displayed on small tablets
        spaceBetween: 10,
      },
      // Tablets and small laptops
      768: {
        slidesPerView: 2, // Show 2 slides for larger tablets
        spaceBetween: 20,
      },
      // Larger screens
      1024: {
        slidesPerView: 4, // Show 4 slides for larger screens
        spaceBetween: 30,
      },
    },
  };

  return (
    <div className="container mx-auto p-8">
      <h3 className="text-lg text-center md:text-left">Come Join Us</h3>
      <h2 className="text-4xl font-bold mb-6 max-w-xl text-center md:text-left">
        OUR CLIENT PORTFOLIO IS EXTENSIVE AND DIVERSE.
      </h2>
      {/* Swiper Slider */}
      <div className="relative">
        <Swiper {...settings} ref={swiperRef}>
          {projects.map((project, index) => (
            <SwiperSlide key={index}>
              <div className="relative group rounded-lg overflow-hidden shadow-md">
                {/* Year Label */}
                {/* <div className="absolute top-2 left-2 bg-white text-black px-2 py-1 rounded-lg font-bold">
                  {project.year}
                </div> */}

                {/* Image */}
                <img
                  src={project.image}
                  alt={project.title}
                  className="w-full h-88 object-cover rounded-lg"
                />

                {/* Dark Overlay */}
                <div className="absolute inset-0 bg-black opacity-0 group-hover:opacity-70 transition-opacity"></div>

                {/* Title and Category */}
                <div className="absolute bottom-0 left-0 p-4 text-white">
                  {/* <p className="bg-white text-black px-2 py-1 rounded-lg">{project.category}</p>
                  <h3 className="text-lg font-bold mt-2">{project.title}</h3> */}
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>

        {/* Navigation Buttons Positioning */}
        <div className="absolute top-4 right-4 flex space-x-2 z-10">
          <button
            className="slider-button bg-white rounded-full p-2 shadow-lg"
            onClick={() => swiperRef.current.swiper.slidePrev()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 text-black"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M15 19l-7-7 7-7"
              />
            </svg>
          </button>
          <button
            className="slider-button bg-white rounded-full p-2 shadow-lg"
            onClick={() => swiperRef.current.swiper.slideNext()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 text-black"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M9 5l7 7-7 7"
              />
            </svg>
          </button>
        </div>
      </div>

      {/* Sliding Line Indicator */}
      <div
        className="relative mt-4 h-1 bg-blue-500 rounded transition-all"
        style={{
          width: `${100 / projects.length}%`, // Width based on the number of slides
          transform: `translateX(${activeIndex * (100 / projects.length)}%)`, // Position based on the active index
          marginLeft: "auto",
          marginRight: "auto",
        }}
      ></div>
    </div>
  );
};

export default PortfolioSlider;
