import React from "react";
import ReactDOM from "react-dom/client";
import "swiper/css/bundle";
import "./index.css";
import Home from "./Components/Home/Home";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import AboutUs from "./Components/AboutUs/AboutUs";
import ContactUs from "./Components/ContactUs/ContactUs";
import Couriers from "./Components/Courier/Courires";
import OurServices from "./Components/Services/ParentServies";
import OurServicesDetails from "./Components/Services/OurServicesDetails";
import ArchitectureInterior from "./Components/Services/ArchitectureInterior";
import StructuralEngineering from "./Components/Services/StructuralEngineering";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import OurMissionAndVision from "./Components/Home/OurMissionAndVision";
import RealEstate from "./Components/Services/RealEstate";
import ProjectManagement from "./Components/Services/ProjectManagement";
import DesignBuild from "./Components/Services/DesignBuild";
import Valuation from "./Components/Services/Valuation";
import UrbanPlanning from "./Components/Services/UrbanPlanning";
import Infrastructure from "./Components/Services/Infrastructure";
import Landscaping from "./Components/Services/Landscaping";
import SurveySolutions from "./Components/Services/SurveySolutions";
import AOS from "aos";
import "aos/dist/aos.css";
// Initialize AOS
AOS.init();
const appRouter = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <> Error Page not found</>,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/aboutUs",
        element: <AboutUs/>,
      },
      {
        path: "/ourServices",
        element: <OurServices/>,
      },
      {
        path: "/ourServicesDetails",
        element: <OurServicesDetails/>,
      },
      {
        path: "/architectureInterior",
        element: <ArchitectureInterior/>,
      },
      {
        path: "/structuralEngineering",
        element: <StructuralEngineering/>,
      },
      {
        path: "/realEstate",
        element: <RealEstate/>,
      },
      {
        path: "/projectManagement",
        element: <ProjectManagement/>,
      },
      {
        path: "/valuation",
        element: <Valuation/>,
      },
      {
        path: "/urbanPlanning",
        element: <UrbanPlanning/>,
      },
      {
        path: "/infrastructure",
        element: <Infrastructure/>,
      },
      {
        path: "/designBuild",
        element: <DesignBuild/>,
      },
      {
        path: "/landscaping",
        element: <Landscaping/>,
      },
      {
        path: "/surveySolutions",
        element: <SurveySolutions/>,
      },
      {
        path: "/designBuild",
        element: <DesignBuild/>,
      },
      {
        path: "/ourMission",
        element: <OurMissionAndVision/>,
      },
      {
        path: "/ourVision",
        element: <OurMissionAndVision/>,
      },
      {
        path: "/career",
        element: <Couriers/>,
      },
      {
        path: "/ContactUs",
        element: <ContactUs/>,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<RouterProvider router={appRouter} />);

reportWebVitals();
