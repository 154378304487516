import React from 'react';
import img1 from "../image/Rectangle 284.jpg";
import img2 from "../image/Vector (2).png";
import img3 from "../image/Rectangle 286.jpg";
import img4 from "../image/Vector (1).png";
import img5 from "../image/Rectangle 285.jpg";
import img6 from "../image/Map icon.png";

function ContactCards() {
  return (
    <section className="flex flex-wrap w-full px-8 py-8 sm:py-6 lg:py-16 items-center justify-center gap-4">
      <div className="bg-white border-gray-200 shadow dark:bg-gray-800 dark:border-gray-700 w-full sm:w-1/2 lg:w-1/4 md:w-2/5 border">
        <img className="w-full img-contact" src={img1} alt="img1" />
        <div className="flex">
          <div className="p-5">
            <img className="w-10" src={img2} alt="icon" />
          </div>
          <div className="p-5">
            <p className="mb-2 font-bold tracking-tight text-gray-900 dark:text-white">
              MAKE A QUOTE
            </p>
            <h6 className="mb-2 tracking-tight text-gray-900 dark:text-white text-[10px] h-[10vh]">
            ktrcengineeringservices@gmail.com
            </h6>
          </div>
        </div>
      </div>
      <div className="bg-white border-gray-200 shadow dark:bg-gray-800 dark:border-gray-700 w-full sm:w-1/2 lg:w-1/4 md:w-2/5 border">
        <img className="w-full img-contact" src={img3} alt="img2" />
        <div className="flex">
          <div className="p-5">
            <img className="w-10" src={img4} alt="icon" />
          </div>
          <div className="p-5">
            <p className="mb-2 font-bold tracking-tight text-gray-900 dark:text-white">
              CALL US 24/7
            </p>
            <h6 className="mb-2  tracking-tight text-gray-900 dark:text-white text-[10px] h-[10vh]">
           +917389424444
            </h6>
          </div>
        </div>
      </div>
      <div className="bg-white border-gray-200 shadow dark:bg-gray-800 dark:border-gray-700 w-full sm:w-1/2 lg:w-1/4 md:w-2/5 border">
        <img className="w-full img-contact" src={img5} alt="img3" />
        <div className="flex">
          <div className="p-5">
            <img className="w-16" fill="black" src={img6} alt="icon" />
          </div>
          <div className="p-5">
            <p className="mb-2 font-bold tracking-tight text-gray-900 dark:text-white">
              WORK STATION
            </p>
            <h6 className="mb-2  tracking-tight text-gray-900 dark:text-white text-[10px] h-[10vh]">
            201, Labbaik Regency, Near Navneet Plaza, Old Palasia Indore, Madhya Pradesh – 452001
            </h6>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ContactCards;
