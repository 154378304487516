// App.js
import React, { useState, useEffect } from 'react';
import './App.css';
import Header from './Components/Header/Header';
import Footer from './Components/Footer/Footer';
import Loader from './Loader';
import { Outlet } from 'react-router-dom';
import '@fortawesome/fontawesome-free/css/all.css';

function App() {
  const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setLoading(false);
  //   }, 2000);

  //   return () => clearTimeout(timer);
  // }, []);

  if (loading) {
    // return <Loader />;
  }

  return (
    <div className="App relative">
      <Header />
      <Outlet />
      <Footer />
    </div>
  );
}

export default App;
