import React from "react";
import "../ContactUs/ContactUs.css";
const ContactForm = () => {
  return (
    <>
    <section className="ContactBgImage bg-gray-100 min-h-screen flex flex-col justify-center items-center py-12 px-4">
      {/* Centered Text Section */}
      <div className="text-center mb-12">
        <h3 className="text-lg">Contact Us</h3>
        <h2 className="text-4xl font-bold mt-4">GET IN TOUCH WITH US</h2>
        <p className="text-gray-500 mt-6 max-w-2xl">
          Morbi vel ex rhoncus purus tincidunt finibus. Aliquam in neque nibh.
          Aenean non nisi ac urna convallis fermentum nec ut leo.
        </p>
      </div>

      {/* Form Section */}
      <div className="w-full max-w-3xl">
        <form className="space-y-6">
          {/* Inputs Row */}
          <div className="flex flex-col sm:flex-row sm:space-x-4 space-y-4 sm:space-y-0">
            <input
              type="text"
              placeholder="Full Name"
              className="flex-1 px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <input
              type="email"
              placeholder="Email"
              className="flex-1 px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <input
              type="tel"
              placeholder="Phone"
              className="flex-1 px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>

          {/* Message Field */}
          <textarea
            placeholder="Message"
            className="w-full h-32 px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          ></textarea>

          {/* Terms and Button Row */}
          <div className="flex flex-col sm:flex-row items-center justify-between space-y-4 sm:space-y-0">
            <div className="flex items-center">
              <input
                type="checkbox"
                id="terms"
                className="w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
              />
              <label htmlFor="terms" className="ml-2 text-sm text-gray-700">
                I agree to the terms and conditions.
              </label>
            </div>

            <button
              type="submit"
              className="button"
            >
              SEND MESSAGE
            </button>
          </div>
        </form>
      </div>
    </section>
     <div className="p-4 lg:px-32">
     <iframe
       src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14724.14502456417!2d75.8637851!3d22.6896942!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3962fd72b5178f79%3A0x82e42d313fe5bf62!2sKTRC%20Skill%20Development%20Services!5e0!3m2!1sen!2sin!4v1712064060584!5m2!1sen!2sin"
       className="w-full h-[12rem] lg:h-[250px] border iframe"
       allowFullScreen
       loading="lazy"
       referrerPolicy="no-referrer-when-downgrade"
     />
   </div>
   </>
  );
};

export default ContactForm;
