import React, { useState } from "react";
import logo from "./headerImg/ktrcEmgineering.png";
import "./headerstyle.css";
import NavItems from "./NavItems";
import { HashLink as Link } from "react-router-hash-link";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isHoveredItem, setIsHoveredItem] = useState("");
  const [isTopRightModalOpen, setIsTopRightModalOpen] = useState(false);

  const handleNavItemClick = (item) => {
    setIsMenuOpen(false);
    setIsHoveredItem(item);
  };

  const handleHoveredItem = (item) => {
    setIsHoveredItem(item);
  };

  const leftModalOpen = () => {
    setIsTopRightModalOpen(true);
  };

  const leftModalClose = () => {
    setIsTopRightModalOpen(false);
  };
  

  return (
    <>
      <header className="px-4 xl:px-24 font-[sans-serif] min-h-[85px]">
        <div>
          <div className="md:flex block pt-4 flex-wrap items-center text-center gap-4 ">
            <Link to="/" className="flex justify-center md:justify-start ">
              <img src={logo} alt="logo" className="w-16" />
            </Link>
            <div className="flex items-center py-2 justify-center max-lg:ml-auto lg:order-1">
              <button
                id="toggle"
                onClick={() => setIsMenuOpen(!isMenuOpen)}
                className="md:hidden ml-7 "
              >
                <svg
                  className="w-7 h-7"
                  fill="#eeeee"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>
            <ul
              id="collapseMenu"
              style={{ display: isMenuOpen ? "block" : "none" }}
              className="md:!flex md:w-9/12 max-lg:space-y-2 max-md:hidden max-lg:py-4 max-lg:w-1/2 lg:ml-auto lg:mr-8 items-baseline"
            >
              <NavItems
                name="home"
                handleNavItemClick={handleNavItemClick}
                isHoveredItem={isHoveredItem}
                handleHoveredItem={handleHoveredItem}
              >
                <Link to="/" smooth>
                  Home
                </Link>
              </NavItems>
              <NavItems
                name="aboutUs"
                handleNavItemClick={handleNavItemClick}
                isHoveredItem={isHoveredItem}
                handleHoveredItem={handleHoveredItem}
              >
                <Link to="/aboutUs" smooth>
                  About Us
                </Link>
              </NavItems>
              <NavItems
                name="ourServices"
                handleNavItemClick={handleNavItemClick}
                isHoveredItem={isHoveredItem}
                handleHoveredItem={handleHoveredItem}
              >
                <Link to="/ourServices" smooth>
                  Our Services
                </Link>
              </NavItems>
              <NavItems
                name="ourMissionAndVision"
                handleNavItemClick={handleNavItemClick}
                isHoveredItem={isHoveredItem}
                handleHoveredItem={handleHoveredItem}
              >
                <Link to="/#ourMissionAndVision" smooth={true} duration={500}>
                  Our Mission & Vision
                </Link>
              </NavItems>
              {/* <NavItems
                name="ourMissionAndVision"
                handleNavItemClick={handleNavItemClick}
                isHoveredItem={isHoveredItem}
                handleHoveredItem={handleHoveredItem}
              >
                <Link to="/#ourMissionAndVision" smooth>
                  Our Vision
                </Link>
              </NavItems> */}
              <NavItems
                name="career"
                handleNavItemClick={handleNavItemClick}
                isHoveredItem={isHoveredItem}
                handleHoveredItem={handleHoveredItem}
              >
                <Link to="/career" smooth>
                  Career
                </Link>
              </NavItems>
              <NavItems
                name="contactUs"
                handleNavItemClick={handleNavItemClick}
                isHoveredItem={isHoveredItem}
                handleHoveredItem={handleHoveredItem}
              >
                <Link to="/contactUs" smooth>
                  Contact Us
                </Link>
              </NavItems>
            </ul>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
