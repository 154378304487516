import React from 'react'
import AboutGroup from './AboutGroup'
import OurCompany from './OurCompany'
import DirectorMessage from './DirectorMessage'
import OurBranches from './OurBranches'

function AboutUs() {
  return (
    <>
    <AboutGroup/>
    <OurCompany/>
    <DirectorMessage/>
    <OurBranches/>
    </>
  )
}

export default AboutUs