import React, { useState, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay } from 'swiper/modules'; // Import Autoplay module
import 'swiper/css'; // Main Swiper styles
import 'swiper/css/navigation'; // For Navigation styles
import img from "../image/Group 1124.png";
import img1 from "../image/Group 2201953.png";
import img2 from "../image/The Beach House.png";
import img3 from "../image/Group 1124.png";
import img4 from "../image/Group 2201953.png";
import img5 from "../image/The Beach House.png";

const PortfolioSlider = () => {
  const [activeIndex, setActiveIndex] = useState(0); // State to track the active slide
  const swiperRef = useRef(null); // Reference for the Swiper instance
  const projects = [
    { year: '2023', title: 'The Beach House', category: 'Interior', image: img },
    { year: '2020', title: 'The Beach House', category: 'Interior', image: img1 },
    { year: '2023', title: 'The Beach House', category: 'Interior', image: img2 },
    { year: '2020', title: 'The Beach House', category: 'Interior', image: img3 },
    { year: '2020', title: 'The Beach House', category: 'Interior', image: img4 },
    { year: '2020', title: 'The Beach House', category: 'Interior', image: img5 },
  ];

  const settings = {
    modules: [Navigation, Autoplay], // Include Autoplay module
    spaceBetween: 20,
    slidesPerView: 1, // Default for mobile
    navigation: false, // Disable default navigation
    autoplay: {
      delay: 3000, // Delay between slide changes (in milliseconds)
      disableOnInteraction: false, // Keep autoplay running after interaction
    },
    onSlideChange: (swiper) => {
      setActiveIndex(swiper.activeIndex); // Update active slide index
    },
    breakpoints: {
      480: {
        slidesPerView: 1, // 1 slide for small screens
        spaceBetween: 10,
      },
      640: {
        slidesPerView: 1, // 1 slide for small screens
        spaceBetween: 10,
      },
      768: {
        slidesPerView: 2, // 2 slides for tablets
        spaceBetween: 20,
      },
      1024: {
        slidesPerView: 3, // 3 slides for desktops
        spaceBetween: 30,
      },
    },
  };

  return (
    <div className="container mx-auto p-8">
      <h3 className="text-lg text-center md:text-left">Our Work</h3>
      <h2 className="text-4xl font-bold mb-6 text-center md:text-left">Our Work Portfolio</h2>

      {/* Swiper Slider */}
      <div className="relative">
        <Swiper {...settings} ref={swiperRef}>
          {projects.map((project, index) => (
            <SwiperSlide key={index}>
              <div className="relative group rounded-lg overflow-hidden shadow-md">
                {/* Image */}
                <img
                  src={project.image}
                  alt={project.title}
                  className="w-full h-80 rounded-lg"
                />
                {/* Dark Overlay */}
                <div className="absolute inset-0 bg-black opacity-0 group-hover:opacity-70 transition-opacity"></div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>

        {/* Navigation Buttons */}
        <div className="absolute top-4 right-4 flex space-x-2 z-10">
          <button className="slider-button bg-white rounded-full p-2 shadow-lg" onClick={() => swiperRef.current.swiper.slidePrev()}>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-black" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7" />
            </svg>
          </button>
          <button className="slider-button bg-white rounded-full p-2 shadow-lg" onClick={() => swiperRef.current.swiper.slideNext()}>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-black" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
            </svg>
          </button>
        </div>
      </div>

      {/* Sliding Line Indicator */}
      <div className="relative mt-4 h-1 bg-blue-500 rounded transition-all"
        style={{
          width: `${100 / projects.length}%`,  // Width based on the number of slides
          transform: `translateX(${activeIndex * (100 / projects.length)}%)`, // Position based on the active index
          marginLeft: 'auto',
          marginRight: 'auto',
        }}></div>
    </div>
  );
};

export default PortfolioSlider;
