import React from "react";
import img1 from "../image/Rectangle 3464506.png";
import img from "../image/Rectangle 3464507.png";
import img2 from "../image/Rectangle 3464508.png";
import img3 from "../image/Group 1144.png";
import img4 from "../image/logo.d242736293aa589515d3.png";
import img5 from "../image/ktrcagro-02 1.png";
const Branches = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex flex-col md:flex-row items-start justify-center gap-20">
        {/* Left Side - Title Section */}
        <div className="md:w-1/3 mb-10 md:mb-0 sticky top-0 bg-white z-10">
          <h3 className="text-lg text-center md:text-left">KTRC Group</h3>
          <h2 className="text-4xl font-bold text-gray-800 mb-4 text-center md:text-left">
            OUR KTRC BRANCHES
          </h2>
          <p className="text-sm text-gray-600">
            We’re always looking for creative, talented self-starters to join
            the KTRC group. Check out our open roles below and fill out an
            application.
          </p>
        </div>

        {/* Right Side - Branches Section */}
        <div className="md:w-1/3 space-y-8">
          {/* Branch 1 */}
          <div className="p-6 mb-4">
            <div className="flex justify-between">
              <div>
              <h3 className="text-xl font-semibold text-[#34438C]">
                  2016 
                </h3>
                <h3 className="text-xl font-semibold text-[#34438C]">
                  Head Office
                </h3>
                <p className="font-medium text-gray-600 mb-2">
                  CEO - Er. Chitranshu Kolhekar
                </p>
              </div>
              <div>
                <img src={img3} alt="" className="w-20"></img>
              </div>
            </div>
            <h4 className="text-lg font-bold text-gray-800 mb-2">
              KTRC Engineering Services
            </h4>
            <p className="text-gray-600 mb-4">
              Established in 2014 by Er. Chitranshu Kolhekar, KTRC Group is a
              conglomerate excelling in Construction & Consultancy, Real Estate,
              Land Survey, IT, Architects & Building Designing, and Skill
              Development.
            </p>
            <img
              src={img}
              alt="KTRC Engineering Services"
              className="h-auto rounded-lg"
            />
          </div>

          {/* Branch 2 */}
          <div className="p-6 mb-4">
            <div className="flex justify-between">
              <div>
                <h3 className="text-xl font-semibold text-[#34438C]">
                  2016
                </h3>
                <h3 className="text-xl font-semibold text-[#34438C]">
                  Branch Office
                </h3>
                <p className="font-medium text-gray-600 mb-2">
                CEO - Er. Chitranshu Kolhekar
                </p>
              </div>
              <div>
                <img src={img4} alt="" className="w-28"></img>
              </div>
            </div>
            <h4 className="text-lg font-bold text-gray-800 mb-2">
              KSVSoftTech
            </h4>
            <p className="text-gray-600 mb-4">
              KSVSoftTech is making strides in the digital world, offering
              top-notch IT solutions.
            </p>
            <img
              src={img1}
              alt="KSVSoftTech"
              className="h-auto rounded-lg"
            />
          </div>
          {/* Branch 3 */}
          <div className="p-6 mb-4">
            <div className="flex justify-between">
              <div>
                <h3 className="text-xl font-semibold text-[#34438C]">
                  2016
                </h3>
                <h3 className="text-xl font-semibold text-[#34438C]">
                  Branch Office
                </h3>
                <p className="font-medium text-gray-600 mb-2">
                CEO - Er. Chitranshu Kolhekar
                </p>
              </div>
              <div>
                <img src={img5} alt="" className="w-20"></img>
              </div>
            </div>
            <h4 className="text-lg font-bold text-gray-800 mb-2">
            KTRC Agro & Food Industry
            </h4>
            <p className="text-gray-600 mb-4">
            KTRC Agro & Food Industry is making strides in the digital world, offering
              top-notch IT solutions.
            </p>
            <img
              src={img2}
              alt="KSVSoftTech"
              className="h-auto rounded-lg"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Branches;
