import React, { useEffect, useState, useRef } from 'react';
import img from "../image/Group 2201977.png";
import img2 from "../image/Vector-5.png";
import img1 from "../image/Group 2201996.png";
import img3 from "../image/Rectangle 245.png";
import img4 from "../image/Group 1109.png";

const KtrcGroup = () => {
  const [isVisible, setIsVisible] = useState(false);
  const componentRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const [entry] = entries;
        if (entry.isIntersecting) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      },
      {
        threshold: 0.2,
      }
    );

    if (componentRef.current) {
      observer.observe(componentRef.current);
    }

    return () => {
      if (componentRef.current) {
        observer.unobserve(componentRef.current);
      }
    };
  }, []);

  return (
    // <div ref={componentRef} className="mb-12 flex flex-col md:flex-row items-start justify-between px-4 py-8">
    <div ref={componentRef} className="mb-12 flex items-start justify-between px-4 py-8 collab-img1">
      {/* Left Side: Animated Images */}
      <div className="relative w-full md:w-1/2 p-4 flex items-start justify-center collab-img">
        {/* Image 1: Slides from left */}
        <img
          src={img1}
          alt="Building"
          className={`absolute rounded-lg shadow-lg w-80 transition-transform duration-1000 ease-out ${
            isVisible ? 'translate-x-0 opacity-100' : '-translate-x-full opacity-0'
          }`}
          style={{ zIndex: 10 }}
        />
        {/* Image 2: Slides from bottom */}
        <img
          src={img3}
          alt="Building"
          className={`absolute rounded-lg shadow-lg w-80 transition-transform duration-1000 ease-out delay-500 ${
            isVisible ? 'translate-y-0 opacity-100' : 'translate-y-full opacity-0'
          }`}
        />
      </div>

      {/* Right Side: Text Content */}
      <div className="w-full md:w-1/2 p-4 ">
        {/* Top Content */}
        <div
          className={`transition-transform duration-1000 ${
            isVisible ? 'translate-y-0 opacity-100' : '-translate-y-full opacity-0'
          }`}
        >
          <h3 className="text-lg">About Us</h3>
          <h2 className="text-4xl font-bold mb-4">OUR KTRC GROUPS</h2>
          <p className="text-gray-600 mb-2">
            KTRC GROUP is a group of multiple companies delivering quality services in fields like Construction &
            Consultancy, Real Estate, Land Survey, etc.
          </p>
          <p className="text-gray-600 mb-4">
            We understant a verified project for a wide range of client tele from small private development to large goverment projects.
          </p>
        </div>

        {/* Bottom Content */}
        <div
          className={`transition-transform duration-1000 delay-500 ${
            isVisible ? 'translate-y-0 opacity-100' : 'translate-y-full opacity-0'
          }`}
        >
          <div className="flex gap-4">
            <ul className="list-disc text-gray-600 mb-6 card-group">
              <p className="flex items-center gap-4 mb-2 text-black">
                <img src={img2} alt="" className="w-4" />
                100% Satisfaction Work
              </p>
              <p className="flex items-center gap-4 mb-2 text-black">
                <img src={img2} alt="" className="w-4" />
                Certified Engineer Team
              </p>
              <p className="flex items-center gap-4 mb-2 text-black">
                <img src={img2} alt="" className="w-4" />
                Flexible & Cost Effective
              </p>
            </ul>
            <ul className="list-disc ml-5 text-gray-600 mb-6">
              <img src={img} alt="" className="w-32" />
            </ul>
          </div>
          <div className="flex gap-8">
            <button className="button-1 text-white px-4 py-2 rounded">More Info</button>
           <img src={img4} alt="" className='w-44'></img>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KtrcGroup;
