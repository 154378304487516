import React from 'react';
import "./ContactUs.css"
function SupportUs() {
  return (
    <section className="w-full h-[51vh] flex flex-col justify-center items-center relative">
      <div className="background-overlay absolute inset-0 px-4">
       <div className="CarrerHome"></div>
      </div>
      <div className="w-11/12 md:w-3/4 lg:w-2/3 xl:w-9/12 flex flex-col justify-center items-center gap-4 z-10 relative">
        <h4 className="text-4xl md:text-4xl text-white">Contact Us</h4>
        <button className="flex gap-2">
          <span className="text-stone-600 text-white">Home</span>
          <span>
            <i class="fa-solid fa-angle-right"></i>
          </span>
          <span className="text-stone-600 text-white">Contact Us</span>
          </button>
      </div>
    </section>
  );
}

export default SupportUs;
